// import React from 'react'

// const Footer = () => {
//   return (
//    <>
//     <footer>
//     <div className="footer-top text-center">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-lg-10 text-center">
//             <h4 className="navbar-brand">Crimsons <span className="dot">Systems.</span></h4>
//             <p>
//             An innovative IT company delivers custom software solutions to
// businesses. With a skilled team, it&#39;s known for excellence and
// innovation in information technology. The company&#39;s mission is to help
// businesses use technology to grow, increase productivity, and
// streamline operations. They believe technology is a powerful tool for
// innovation and achieving business goals, committed to providing clients
// with solutions for success in today&#39;s digital world.
//             </p>
//             <div className="col-auto social-icons ms-lg-3">
//   <a href="https://www.facebook.com/profile.php?id=61554376640929"><i className="bx bxl-facebook" /></a>
//   <a href="https://twitter.com/Crimsons ssystems"><i className="bx bxl-twitter" /></a>
//   <a href="https://www.instagram.com/Crimsons ssystems/"><i className="bx bxl-instagram" /></a>
//   <a href="https://www.linkedin.com/in/Crimsons s-systems-60b21a2a3/"><i className="bx bxl-linkedin" /></a>
// </div>



// <div className="col-auto social-icons mt-2">
//                   <a href="#" onclick="downloadFile('attendance.pdf')" title="Download Attendance"><i className="bx bx-download"></i></a>
//                   <a href="#" onclick="downloadFile('lms.pdf')" title="Download LMS app"><i className="bx bx-download"></i></a>
//                   <a href="#" onclick="downloadFile('school.pdf')" title="Download School app"><i className="bx bx-download"></i></a>
//                 </div>


//             <div className="col-auto conditions-section">
//               <a href="../Home/privacy">Privacy</a>
//               <a href="../Home/Terms">Terms</a> 
//               <a href="../Home/disclaimer">Disclaimer</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="footer-bottom text-center">
//       <p className="mb-0">Copyright Crimsons Systems 2023. All rights Reserved</p>  <a hrefs />
//     </div>
//   </footer>
//    </>
//   )
// }

// export default Footer

import React, { useState } from 'react';
import lms from "../moapp/Creo Smart.apk"
const Footer = () => {
  const [pdfPath, setPdfPath] = useState(lms);
  // Function to handle file download
  const downloadFile = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <>
      <footer>
        <div className="footer-top text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <h4 className="navbar-brand">Crimsons <span className="dot">Systems.</span></h4>
                <p>
                  An innovative IT company delivers custom software solutions to
                  businesses. With a skilled team, it&#39;s known for excellence and
                  innovation in information technology. The company&#39;s mission is to help
                  businesses use technology to grow, increase productivity, and
                  streamline operations. They believe technology is a powerful tool for
                  innovation and achieving business goals, committed to providing clients
                  with solutions for success in today&#39;s digital world.
                </p>
                <div className="col-auto social-icons ms-lg-3">
                  <a href="https://www.facebook.com/profile.php?id=61554376640929"><i className="bx bxl-facebook" /></a>
                  <a href="https://twitter.com/Crimsons ssystems"><i className="bx bxl-twitter" /></a>
                  <a href="https://www.instagram.com/Crimsons ssystems/"><i className="bx bxl-instagram" /></a>
                  <a href="https://www.linkedin.com/in/Crimsons s-systems-60b21a2a3/"><i className="bx bxl-linkedin" /></a>
                </div>

                <div className="col-auto social-icons mt-2">
                  {/* Add onClick event to handle download */}


                  <a href="#" onClick={() => downloadFile('https://drive.usercontent.google.com/download?id=13nex7oD3-MCjD8TypPXbNDuMqxuJLjhx&export=download&authuser=0')} title="Download School app"><i className="bx bx-download"></i></a>
                  {/* Add the link to download Creo Smart APK */}
                  <a href="#" onClick={() => downloadFile(pdfPath)}  title="Download LMS app"><i className="bx bx-download"></i></a>
                  <a href="#" onClick={() => downloadFile('attendance.pdf')} title="Download Attendance"><i className="bx bx-download"></i></a>
                </div>

                <div className="col-auto conditions-section">
                  <a href="../Home/privacy">Privacy</a>
                  <a href="../Home/Terms">Terms</a> 
                  <a href="../Home/disclaimer">Disclaimer</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <p className="mb-0">Copyright Crimsons Systems 2023. All rights Reserved</p>  
          <a hrefs />
        </div>
      </footer>
    </>
  );
};

export default Footer;
