
import './App.css';
import { Routes,Route } from 'react-router-dom';
import Homepage from "./pages/Homepage"
import About from "./pages/About"
import Contact from './pages/Conatact'
import Portfolio from './pages/Portfolio';
import Pagenotfount from './pages/Pagenotfount';
import Boardmember from './pages/Boardmember';
import Blog from './pages/Blog';
import Services from "./pages/Services"
import Leadershipteam from './pages/Leadershipteam';


function App() {
  return (
   
   <>
 <Routes>
  <Route path="/" element={<Homepage/>}/>
  <Route path="/about" element={<About/>}/>
  <Route path="/contact" element={<Contact/>}/>
  <Route path="/contact" element={<Contact/>}/> 
  <Route path="/Boardmember" element={<Boardmember/>}/> 
  <Route path="/Blog" element={<Blog/>}/> 
  <Route path="/Portfolio" element={<Portfolio/>}/> 
  <Route path="/team" element={<Leadershipteam/>}/> 
  <Route path="/services" element={<Services/>}/> 



  <Route path="/*" element={<Pagenotfount/>}/>
  
  

  

  

 </Routes>
  
   </>
  );
}

export default App;
