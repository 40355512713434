// import React, { useState, useEffect } from 'react';
// import Layout from '../components/Layout/Layout';
// import { Carousel } from 'react-bootstrap';
// import axios from 'axios';
// const basurl=process.env.REACT_APP_BASE_URL; 
// const Portfolio = () => {
//   const [projects, setProjects] = useState([]);

//   useEffect(() => {
//     // Fetch project data, including image URLs, from your API 
  
//     axios.get(`${basurl}api/getAllPortfolios`)
//       .then(response => {
//         setProjects(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching project data:', error);
//       });
//   }, []);

//   console.log(projects)
//   return (
//     <>
//       <Layout>
//         <section className="bg-light" id="portfolio">
//           <div className="container">
//             <div className="row">
//               <div className="col-12">
//                 <div className="intro">
//                   <h6>Work</h6>
//                   <h1>Successful projects</h1>
//                   <p className="mx-auto">
//                   At the forefront of the industry, we&#39;re continually innovating and
// delivering cutting-edge solutions to our clients&#39; most complex
// challenges. Our dedication to innovation drives us to develop and refine
// successful projects focus on developing
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Carousel>
//             {projects.map((project, index) => (
//               <Carousel.Item key={index}>
//                  {project.image && (
//                   <img className="d-block w-100" alt={`Dynamic Project ${project.id}`}    src={`${basurl}${project.image}`} />
//                 )}
//                 <Carousel.Caption>
//                   <h1 style={{color:"blue"}}>{project.description}</h1>
//                   <h3 style={{color:"red"}}>{project.name}</h3>
//                 </Carousel.Caption>
//               </Carousel.Item>
//             ))}
//           </Carousel>
//         </section>
//       </Layout>
//     </>
//   );
// };

// export default Portfolio;



import React from 'react';
import Layout from '../components/Layout/Layout';
import { Carousel } from 'react-bootstrap';
import img1 from "../img/school_erp.png"
import img2 from "../img/friendzbazzaar.png"
import img3 from "../img/LMSSCREEN.png"
const Portfolio = () => {
  return (
    <>
      <Layout>
        <section className="bg-light" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="intro">
                  <h6>Work</h6>
                  <h1>Successful projects</h1>
                  <p className="mx-auto">
                    At the forefront of the industry, we're continually innovating and
                    delivering cutting-edge solutions to our clients' most complex
                    challenges. Our dedication to innovation drives us to develop and refine
                    successful projects focus on developing
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption>
                <h1 style={{color:"blue"}}>ERP</h1>
                <h3 style={{color:"red"}}>Scohool ERP</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption>
                <h1 style={{color:"blue"}}>E-Commerce</h1>
                <h3 style={{color:"red"}}>Friendz Bazzar</h3>
              </Carousel.Caption>
            </Carousel.Item> 

            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Second slide" />
              <Carousel.Caption>
                <h1 style={{color:"blue"}}>LMS</h1>
                <h3 style={{color:"red"}}>LMS</h3>
              </Carousel.Caption>
            </Carousel.Item>
            {/* Add more Carousel.Items as needed */}
          </Carousel>
        </section>
      </Layout>
    </>
  );
};

export default Portfolio;
