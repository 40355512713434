import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import vikash from "../imgececk/vikas.jpg";
import mohit from "../img/teamimages/mohitbali1.jpg";

const basurl = process.env.REACT_APP_BASE_URL;

const Leadershipteam = () => {
  const [leadershipTeam, setLeadershipTeam] = useState([]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${basurl}api/getAllLeaderships`)
      .then(response => response.json())
      .then(data => setLeadershipTeam(data))
      .catch(error => console.error('Error fetching leadership team data:', error));
  }, []);

  return (
    <Layout>
      <section id="team">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="intro">
                <h6>Team</h6>
                <h1>Team Members</h1>
                <p className="mx-auto">
                  Welcome to our Team! Here, collaboration isn't just a word; it's the essence of
                  our success. The individuals within our organization serve as the driving force
                  behind our projects, navigating challenges and achieving remarkable results
                  together.
                  We believe that a strong team is the foundation of our accomplishments.
                  Together, we shape the future of our projects, driven by unity, dedication, and a
                  shared passion for excellence.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {leadershipTeam.map(member => (
              <div key={member.id} className="col-lg-4 col-md-8">
                <div className="team-member">
                  <div className="image">
                    {member.name === 'Vikas Shrivastava' && (
                      <img
                        src={vikash}
                        alt={member.name}
                        style={{ width: '70%', height: '200px' }} // Set fixed dimensions
                      />
                    )}
                    {member.name === 'Mohit Baliwada' && (
                      <img
                        src={mohit}
                        alt={member.name}
                        style={{ width: '70%', height: '200px' }} // Set fixed dimensions
                      />
                    )}
                    <div className="overlay" />
                  </div>
                  <h5>{member.name}</h5>
                  <p>{member.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Leadershipteam;
