// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Layout from '../components/Layout/Layout';
// import blog from "../imgececk/blog3.jpg"
// const basurl = process.env.REACT_APP_BASE_URL;

// const Blog = () => {
//   const [blogs, setBlogs] = useState([]);

//    console.log(basurl);

//   useEffect(() => {
//     axios.get(basurl+`api/getAllBlogs`)
//       .then(response => {
//         const fetchedBlogs = response.data;
//         setBlogs(fetchedBlogs);
//       })
//       .catch(error => {
//         console.error('Error fetching blog data:', error);
//       });
//   }, []);

//   const [expandedBlogs, setExpandedBlogs] = useState([]);

//   const toggleReadMore = (id) => {
//     setExpandedBlogs((prevExpandedBlogs) => {
//       if (prevExpandedBlogs.includes(id)) {
//         return prevExpandedBlogs.filter((blogId) => blogId !== id);
//       } else {
//         return [...prevExpandedBlogs, id];
//       }
//     });
//   };

//   const columnStyle = {
//     width: '30%', // Adjust the width as per your design
//   };

//   const marginBottomStyle = {
//     marginBottom: '20px', // Add margin for spacing between rows
//   };

//   // Function to format date in "2 Jan" format
//   const formatDate = (dateString) => {
//     const options = { day: 'numeric', month: 'short' };
//     return new Date(dateString).toLocaleDateString('en-GB', options);
//   };

//   return (
//     <Layout>
//       <div class="container">
//         <div class="row">
//           <div class="col-12">
//             <div class="intro">
//               <h6>Blog</h6>
//               <h1>Blog Posts</h1>
//               <p class="mx-auto">
//                 In today's digital age, it is essential for businesses to have a strong online presence and
//                 maintain efficient IT systems. However, managing your company's IT needs can be a daunting task,
//                 especially if you lack the necessary expertise and resources. This is where an IT company can help.
//                 Here are five benefits of hiring an IT company for your business.
//                 <br />
//                 Cost-Effective .
//                 Expertise and Experience.
//                 Scalability.
//                 Improved Security.
//                 Focus on Core Business Functions.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <section id="blog">
//         <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginLeft: "9%", marginRight: "9%", marginTop: "-100px" }}>
//           {blogs.map(blog => (
//             <div key={blog.id} style={{ ...columnStyle, ...marginBottomStyle }}>
//               <article className="blog-post">
//                 {/* {blog.image && (
//                   <img alt={`Dynamic Project ${blog.id}`}  src={blog} />
//                 )} */} 
//                 <img src={blog}/>
//                 <a href="#" className="tag">Web Design</a>
//                 <div className="content">
//                 <b>  <small>{formatDate(blog.created_at)}</small></b>
//                   <h5>{blog.name}</h5>
//                   {expandedBlogs.includes(blog.id) ? (
//                     <>
//                       <p>{blog.description}</p>
//                       <button onClick={() => toggleReadMore(blog.id)}>Read Less</button>
//                     </>
//                   ) : (
//                     <>
//                       <p>{blog.description.slice(0, 100)}...</p>
//                       <button onClick={() => toggleReadMore(blog.id)}>Read More</button>
//                     </>
//                   )}
//                 </div>
//               </article>
//             </div>
//           ))}
//         </div>
//       </section>
//     </Layout>
//   );
// };

// export default Blog;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Layout from '../components/Layout/Layout';
// import blogImage from "../imgececk/blog3.jpg"; // Import the static image

// const basurl = process.env.REACT_APP_BASE_URL;

// const Blog = () => {
//   const [blogs, setBlogs] = useState([]);
//   console.log(basurl);

//   useEffect(() => {
//     axios.get(basurl + `api/getAllBlogs`)
//       .then(response => {
//         const fetchedBlogs = response.data;
//         setBlogs(fetchedBlogs);
//       })
//       .catch(error => {
//         console.error('Error fetching blog data:', error);
//       });
//   }, []);

//   const [expandedBlogs, setExpandedBlogs] = useState([]);

//   const toggleReadMore = (id) => {
//     setExpandedBlogs((prevExpandedBlogs) => {
//       if (prevExpandedBlogs.includes(id)) {
//         return prevExpandedBlogs.filter((blogId) => blogId !== id);
//       } else {
//         return [...prevExpandedBlogs, id];
//       }
//     });
//   };

//   const columnStyle = {
//     width: '30%', // Adjust the width as per your design
//   };

//   const marginBottomStyle = {
//     marginBottom: '20px', // Add margin for spacing between rows
//   };

//   // Function to format date in "2 Jan" format
//   const formatDate = (dateString) => {
//     const options = { day: 'numeric', month: 'short' };
//     return new Date(dateString).toLocaleDateString('en-GB', options);
//   };

//   return (
//     <Layout>
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <div className="intro">
//               <h6>Blog</h6>
//               <h1>Blog Posts</h1>
//               <p className="mx-auto">
//               Web design trends are constantly evolving, and with each passing year, new techniques and
// technologies emerge that change the way we create and experience websites.
//                 <br />
//                 Cost-Effective .
//                 Expertise and Experience.
//                 Scalability.
//                 Improved Security.
//                 Focus on Core Business Functions.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <section id="blog">
//         <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginLeft: "9%", marginRight: "9%", marginTop: "-100px" }}>
//           {blogs.map(blog => (
//             <div key={blog.id} style={{ ...columnStyle, ...marginBottomStyle }}>
//               <article className="blog-post">
//                 <img src={blogImage} alt={`Blog ${blog.id}`} /> {/* Use static image */}
//                 <a href="#" className="tag">Web Design</a>
//                 <div className="content">
//                   <b><small>{formatDate(blog.created_at)}</small></b>
//                   <h5>{blog.name}</h5>
//                   {expandedBlogs.includes(blog.id) ? (
//                     <>
//                       <p>{blog.description}</p>
//                       <button onClick={() => toggleReadMore(blog.id)}>Read Less</button>
//                     </>
//                   ) : (
//                     <>
//                       <p>{blog.description.slice(0, 100)}...</p>
//                       <button onClick={() => toggleReadMore(blog.id)}>Read More</button>
//                     </>
//                   )}
//                 </div>
//               </article>
//             </div>
//           ))}
//         </div>
//       </section>
//     </Layout>
//   );
// };

// export default Blog;


import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import blogImage1 from "../img/blog1.jpeg";
import blogImage2 from "../img/blog2.jpeg";
import blogImage3 from "../img/blog3.jpeg";

const Blog = () => {
  const [expandedBlogs, setExpandedBlogs] = useState([]);

  const toggleReadMore = (id) => {
    setExpandedBlogs(prev => {
      if (prev.includes(id)) {
        return prev.filter(blogId => blogId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const columnStyle = {
    width: '30%',
  };

  const marginBottomStyle = {
    marginBottom: '20px',
  };

  const staticBlogs = [
    {
      id: 1,
      name: "Top 10 New Technology Trends",
      created_at: "2024-04-16",
      description: "Web design in 2024: Unveiling the web design trends that will transform your website!",
      image: blogImage1,
      url: "https://sites.google.com/crimsonssystems.com/web-design-trend/home"
    },
    {
      id: 2,
      name: "Web Design Trends",
      created_at: "2024-04-16",
      description: "Top 10 Programming Languages: Discover the most in-demand programming languages for developers this year.",
      image: blogImage2,
      url: "https://sites.google.com/crimsonssystems.com/top-programming-language/home"
    },
    {
      id: 3,
      name: "Top 10 Programming Language Trends",
      created_at: "2024-04-16",
      description: "Top 10 emerging technologies that will transform our world in 2024.",
      image: blogImage3,
      url: "https://sites.google.com/crimsonssystems.com/top-tech-trend/home"
    },
  ];

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="intro">
              <h6>Blog</h6>
              <h1>Blog Posts</h1>
              <p className="mx-auto">
                "Welcome to Our Blogs: Where Innovation Meets Technology!"
                Explore the intersection of technology and imagination with our blog, your guide to the latest trends, innovations, and breakthroughs in the world of IT. Join us on a journey of discovery as we explore tomorrow's possibilities today. Stay connected for informative and engaging posts covering technology trends, programming, and web design, with regular updates and insights.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section id="blog">
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginLeft: "9%", marginRight: "9%", marginTop: "-100px" }}>
          {staticBlogs.map(blog => (
            <div key={blog.id} style={{ ...columnStyle, ...marginBottomStyle }}>
              <article className="blog-post">
                <img src={blog.image} alt={`Blog ${blog.id}`} />
                <div className="content">
                  <b><small>{formatDate(blog.created_at)}</small></b>
                  <a href={blog.url} target="_blank" rel="noopener noreferrer">
                    <h5>View Blog</h5>
                  </a>
                  {expandedBlogs.includes(blog.id) ? (
                    <>
                      <p>{blog.description}</p>
                      <button onClick={() => toggleReadMore(blog.id)}>Read Less</button>
                    </>
                  ) : (
                    <>
                      <p>{blog.description.slice(0, 100)}...</p>
                      <button onClick={() => toggleReadMore(blog.id)}>Read More</button>
                    </>
                  )}
                </div>
              </article>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default Blog;
