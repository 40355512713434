
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from './../../img/surya.jpg';
const basurl=process.env.REACT_APP_BASE_URL; 
const Navbar = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
       
        const response = await fetch( `${basurl}api/getAllservicess`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div className="top-nav" id="home" >
    <div className="container" >
      <div className="row justify-content-between"  >
        <div className="col-auto">
        <span> <i className="bx bxs-envelope" />{userData?.[0]?.email} </span>
              <span> <i className="bx bxs-phone-call" />{userData?.[0]?.contact}</span>
        </div>
        <div className="col-auto social-icons">
  <a href="https://www.facebook.com/profile.php?id=61554376640929" className="m-1"><i className="bx bxl-facebook"> </i></a>
  <a href="https://twitter.com/Crimsonssystems" className="m-1"><i className="bx bxl-twitter" /></a>
  <a href="https://www.instagram.com/crimsonssystems/" className="m-1"><i className="bx bxl-instagram" /></a>
  <a href="https://www.linkedin.com/in/crimsons-systems-60b21a2a3/" className="m-1"><i className="bx bxl-linkedin" /></a>
  <a href="https://www.youtube.com/channel/UC2a3jn-PUEy0dHw2eEVodnQ" className="m-1"><i className="bx bxl-youtube"> </i></a>
</div>
      </div>
    </div>
  </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container-fluid">
          <a className="imglogo" href="/Home/index">
            <img src={logo} alt="Logo"  />
          </a>
          <a className="navbar-brand" href="/">
      
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
           
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/services">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Portfolio">
                  Portfolio
                </NavLink>
              </li>
           
              <li className="nav-item">
                <NavLink className="nav-link" to="/Boardmember">
                  Board Members
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Blog">
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
            </ul>
            <Link to="/contact" className="btn btn-brand ms-lg-3">
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
