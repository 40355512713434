// import React, { useState, useEffect } from 'react';
// import Layout from '../components/Layout/Layout';
// import axios from 'axios';
// import img1 from "../img/boardmembers/sir.png"

// const basurl=process.env.REACT_APP_BASE_URL; 
// console.log("boardmember",basurl)
// const Boardmember = () => {
//   const [boardMembers, setBoardMembers] = useState([]);

//   useEffect(() => {
//     // Fetch board member data, including image URLs, from your API
    
//     axios.get(`${basurl}api/getallmemberboard`)
//       .then(response => {
//         setBoardMembers(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching board member data:', error);
//       });
//   }, []);
//   console.log(boardMembers)

//   return (
//     <>
//       <Layout>
//         <div>
//           <section id="team">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-12">
//                   <div className="intro">
//                     <h6>Board Members</h6>
//                     <h1>Meet our Board</h1>
//                     <p className="mx-auto">
//                     “The People behind Our Organization: Unlocking Success!&quot;
// Our board members are the guiding force behind our organization&#39;s strategic
// direction and success. Comprised of accomplished leaders from diverse
// backgrounds, they bring invaluable expertise and insights to the organization.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="row justify-content-center">
//                 {boardMembers.map((member, index) => (
//                   <div className="col-lg-4 col-md-8" key={index}>
//                     <div className="team-member">
//                       <div className="image">
//                       {member.image && (
//                         <img alt={`${member.name} img not found`}      src={`${basurl}${member.image}`} />
//                       )}
//                     <div className="social-icons">
//                           <a href={member.facebook}><i className="bx bxl-facebook" /></a>
//                           <a href={member.twitter}><i className="bx bxl-twitter" /></a>
//                           <a href={member.instagram}><i className="bx bxl-instagram" /></a>
//                           <a href={member.linkedin}><i className="bx bxl-linkedin" /></a>
//                         </div>
//                         <div className="overlay" />
//                       </div>
//                       <h5>{member.name}</h5>
//                       <p>{member.description}</p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </section>
//         </div>
//       </Layout>
//     </>
//   );
// };

// export default Boardmember;



import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import axios from 'axios';
import img1 from "../img/boardmembers/sir.png";

const basurl = process.env.REACT_APP_BASE_URL;
console.log("boardmember", basurl);

const Boardmember = () => {
  const [boardMembers, setBoardMembers] = useState([]);

  useEffect(() => {
    // Fetch board member data from your API
    axios.get(`${basurl}api/getallmemberboard`)
      .then(response => {
        setBoardMembers(response.data);
      })
      .catch(error => {
        console.error('Error fetching board member data:', error);
      });
  }, []);

  return (
    <>
      <Layout>
        <div>
          <section id="team">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="intro">
                    <h6>Board Members</h6>
                    <h1>Meet our Board</h1>
                    <p className="mx-auto">
                      “The People behind Our Organization: Unlocking Success!"
                      Our board members are the guiding force behind our organization's strategic
                      direction and success. Comprised of accomplished leaders from diverse
                      backgrounds, they bring invaluable expertise and insights to the organization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                {boardMembers.map((member, index) => (
                  <div className="col-lg-4 col-md-8" key={index}>
                    <div className="team-member">
                      <div className="image">
                        <img alt={`${member.name} img not found`} src={img1} />
                        <div className="social-icons">
                          <a href={member.facebook}><i className="bx bxl-facebook" /></a>
                          <a href={member.twitter}><i className="bx bxl-twitter" /></a>
                          <a href={member.instagram}><i className="bx bxl-instagram" /></a>
                          <a href={member.linkedin}><i className="bx bxl-linkedin" /></a>
                        </div>
                        <div className="overlay" />
                      </div>
                      <h5>{member.name}</h5>
                      <p>swapnil.sijaria@crimsonssystems.com</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Boardmember;
