import React from 'react'
import  { useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../components/Layout/Layout'
import img1 from "../img/about.png"
import img2 from "../img/project1.jpg"
import digi from "../img/Digital Card (102 × 103 px).png"
import logo1 from "../img/Logo designing.png"
import digimarketing from "../img/Digital Marketing.png"
import custom from "../img/Custom web devolpment.png"
import  erp from "../img/school_erp.png"
import lms from "../img/LMSSCREEN.png"
import frandzbazaar from "../img/friendzbazzaar.png"
import  project5  from "../img/project5.jpg"
import project4 from "../img/project4.jpg"
import project2 from "../img/project2.jpg"
 
import mobilapp from "../img/Mobail App development.png"
import team2 from "../img/team_2.jpg"
import vikasteam from "../img/teamimages/vikasteaam.jpg"
import team3 from "../img/team_3.jpg"
import mohit from "../img/teamimages/mohitbali1.jpg"
import { NavLink, Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap'; 
const basurl=process.env.REACT_APP_BASE_URL; 
// import team31 from "../img/team_3.jpg"
const Homepage = () => { 
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch project data, including image URLs, from your API 
  
    axios.get(`${basurl}/getAllPortfolios`)
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching project data:', error);
      });
  }, []);

  console.log(projects)
  return (
    <Layout>
   <div>

  {/* ABOUT */}
  <section id="about">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 py-5">
          <div className="row">
            <div className="col-12">
              <div className="info-box">
                <div className="ms-12">
                  <h4>Welcome To Our Company</h4>
                  <p>
                  Step into a world of endless possibilities where innovation meets
inspiration. At CRIMSONS SYSTEMS, we&#39;re dedicated to providing
you with a seamless and enriching experience of services like
                    <br />some of the common software company
                    services include
                  </p><p> *  Custom software development</p>
                  <p>*  Web development</p>
                  <p> *  Mobile app development</p><p>
                  </p><p> *  Cloud computing</p>
                  <p> *  Software testing and quality </p>
                  <p> *  Maintenance and support </p>
                  <p> *  IT consulting </p>
                  
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="info-box">
                <div className="ms-12">
                  <h4>
                    Innovation
                    Projects
                  </h4>
                  <p className="mx-auto">
                  </p><p>
                  We lead innovative projects by embracing creativity and cutting-edge
technology to revolutionize industries. Our approach involves fostering new
ideas, solving challenges comprehensively, and anticipating future needs
while addressing current obstacles. Join us on our journey of discovery and
innovation, where each project showcases our dedication to excellence and
pushing boundaries. We introduce fresh ideas, methods, or technologies to
tackle challenges creatively. Encourage collaboration, experimentation,
and risk-taking to drive transformative outcomes across various domains
like technology, healthcare, and sustainability. By embracing innovation,
organizations can stay ahead, inspire change, and make significant
contributions to society.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="info-box">
                {/**/}
                <div className="ms-4">
                  <h5 />
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <img src={img1} />
        <img src={img2} /> 
        </div>
      </div>
    </div>
  </section>
  <style dangerouslySetInnerHTML={{__html: "\n    h1 {\n        color: #ff4d29;\n        text-align: left center;\n    }\n" }} />
  <div className="container">
    <h5>Crimsons Systems</h5>
    <p>
    It is a fast-growing IT company based in India. The company has been providing innovative IT
solutions to clients in India and around the world since its  Founded by a
group of IT professionals who recognized the need for high-quality IT services in the region, the
company started with a small team of dedicated professionals who shared a common goal of
providing exceptional IT services to clients.
Today, it has grown into a full-fledged IT services provider, catering to clients across various
industries, with a team of highly skilled professionals; the company delivers superior quality
services and products, making it a leader in the industry. It offers a wide range of IT services,
including software development, web design and development, mobile app development,
cloud computing, and digital marketing.
One of the unique aspects of the company is its focus on innovation. It encourages its
employees to think outside the box and come up with creative solutions to clients&#39; problems,
which has helped it stay ahead of the curve and deliver innovative solutions to clients across
industries such as healthcare, finance, education, and retail.
The company has built a strong reputation for delivering high-quality solutions on time and
within budget. Its clients have praised its professionalism, expertise, and commitment to
delivering exceptional services. In addition to providing IT services, the company also focuses
on employee development by providing regular training sessions to keep them up-to-date with
the latest technologies and trends in the industry.
Overall, the company&#39;s commitment to innovation, professionalism, and expertise has set it
apart from its competitors and helped it build a strong reputation in the industry, with a focus
on delivering exceptional services; it is well-positioned to continue its growth and success in the
years to come.
    </p>
  </div>
  {/* MILESTONE */}
  <section id="milestone">
    <div className="container">
      <div className="row text-center justify-content-center gy-4">
        <div className="col-lg-2 col-sm-6">
          <h1 class="display-4">90K+</h1>
                <p class="mb-0">Happy Clients</p>
            </div>
            <div class="col-lg-2 col-sm-6">
                <h1 class="display-4">45M</h1>
                <p class="mb-0">Lines of code</p>
            </div>
            <div class="col-lg-2 col-sm-6">
                <h1 class="display-4">190</h1>
                <p class="mb-0">Total Downloads</p>
            </div>
            <div class="col-lg-2 col-sm-6">
                <h1 class="display-4">380K</h1>
                <p class="mb-0">YouTube Subscribers</p>
        </div>
      </div>
    </div>
  </section>
  <section id="services" className="text-center">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="intro">
            
            <NavLink className="nav-link" to="/services">
            <h6>Our Services</h6>
                </NavLink>
            <h2>Our Service Map</h2>
            <p className="mx-auto">
              <br />
              Our software company offers a comprehensive suite of services including logo designing, web development, software development, mobile app development, digital cards, and digital marketing. As an outsourcing development company, we specialize in delivering innovative solutions tailored to our clients' needs.
            </p>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img alt="diginotound" src={digimarketing} />
            {/*--     <img src="~/Content/prixima-1.0.0/img/Digital Marketingg.jpg" /> */}
            <h5>Digital Marketing</h5>
            <p>
            Facilitating businesses to enhance their online presence, engage with customers, and boost conversions, services include:
 <ol style={{textAlign:'left'}}>
  <li> Search Engine Optimization (SEO)</li>
  <li> Social Media Marketing (SMM)</li>
  <li> Content Marketing</li>
  <li> Email Marketing</li>
  <li> Pay-Per-Click (PPC) Advertising</li>
  <li> Influencer Marketing</li>
  <li> Affiliate Marketing</li>
  <li> Website Analytics and Conversion Rate Optimization (CRO)</li>
 </ol>


            </p>
         
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img alt="logonotfond" src={logo1} />
            <h5>Logo Designing</h5>
            <p>
            Specializing in crafting unique and impactful logos, our design services focus on capturing your business essence while resonating with your target audience. 
            <ol style={{textAlign:'left'}}>
  <li> Expertise in Logo Designing</li>
  <li> Strategic Approach </li>
  <li>  Creative Solutions</li>
  <li> Attention to Detail</li>
  <li>Customization</li>
  <li>Timely Delivery</li>
  <li> Client Collaboration</li>
  <li> Professionalism</li>
 </ol>
              
         
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img alt="customnotfound" src={custom} />
            <h5>Custom Web Development</h5>
            <p>
            We allow businesses to create a website that is unique and tailored to their specific needs, rather than relying on a pre-built template or theme.
            <ol style={{textAlign:'left'}}>
  <li> 	Developed by Technology Experts</li>
  <li> 	Scalable and Flexible</li>
  <li> 	User-Centric Approach</li>
  <li> 	Responsive Design</li>
  <li>	Security</li>
  <li> 	SEO Optimization</li>
  <li> 	Quality Assurance</li>
  <li> 	User-Centric Approach</li>
 
 </ol>
           
              
           
            </p>

            <br/>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img  alt="mobilenotfound" src={mobilapp} />
            <h5>  Mobile App Development</h5>
            <p>
            We craft beautiful and user-friendly mobile apps for iOS & Android. Including services like
            <ol style={{textAlign:'left'}}>
  <li>App ideation and consulting </li>
  <li>UI/UX design</li>
  <li>Native app development</li>
  <li>Cross-platform app development</li>
  <li>API development and integration</li>
  <li>Quality assurance and testing </li>
  <li>App deployment and maintenance </li>
<li>App analytics and performance monitoring</li>
 <br/>
 <br/>
 </ol>
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service">
            <img alt="diginotfound" src={digi}/>
            <h5>  Digital Card</h5>
            <p>
Digital card
Digital card services offer individuals and businesses an innovative solution to manage and share contact information seamlessly. 
Our digital card :
<ol style={{textAlign:'left'}}>
  <li>Custom Digital Card Design</li>
  <li>Seamless Sharing Options</li>
  <li>Multi-Platform Compatibility</li>
  <li>Comprehensive Contact Information</li>
  <li>Integration with Social Media</li>
  <li>Security and Privacy</li>
  <li>Integration with CRM Systems</li>
<li>Continuous Support and Updates</li>

 </ol>
          
              
            </p>
          </div>
        </div>
        {/*-- <div class="col-lg-4 col-md-6">
           <div class="service">


               <img src="~/Content/prixima-1.0.0/img/Digital Card (102 × 103 px).png" />


               <h5> Outsourcing Development Company</h5>
               <p>
                   Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                   classical Latin literature from
                   N

                   N
               </p>
           </div>  */}
        {/*  <div class="service">

          <img src="/Content/prixima-1.0.0/img/icon6.png" />

          <h5>Custom Web Development</h5>
          <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from
          </p>
      </div>             */}
      </div>
    </div>
  </section> 
  {/* <section className="bg-light" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="intro">
                  <h6>Work</h6>
                  <h1>Successful projects</h1>
                  <p className="mx-auto">
                    Crimsons Systems is at the forefront of the industry, constantly innovating and delivering cutting-edge solutions to our clients' most complex challenges.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Carousel>
            {projects.map((project, index) => (
              <Carousel.Item key={index}>
                 {project.image && (
                  <img className="d-block w-100" alt={`Dynamic Project ${project.id}`} rc={`${basurl}${project.image}`}/>
                )}
                <Carousel.Caption>
                  <h2>{project.description}</h2>
                  <h6>{project.name}</h6>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </section> */}

 
  <section id="blog">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="intro">
          
            <NavLink className="nav-link" to="/Blog">
            <h6>Blog</h6> 
                </NavLink>
            <h1>Blog Posts</h1>
            <p className="mx-auto">
              In today's digital age, it is essential for businesses to have a strong online presence and
              maintain efficient IT systems. However, managing your company's IT needs can be a daunting task,
              especially if you lack the necessary expertise and resources. This is where an IT company can help.
              Here are five benefits of hiring an IT company for your business.
              <br />
              Cost-Effective .
              Expertise and Experience.
              Scalability.
              Improved Security.
              Focus on Core Business Functions.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <article className="blog-post">
            <img alt="project5notfound" src={project5} />
            <a href="#" className="tag">Web Design</a>
            <div className="content">
              <small>01 jan, 2023</small>
              <h5>Web Design trends in 2023</h5>
              <p>
                * Increased Web Accessibility<br />
                * Nostalgia<br />
                * Artificial Intelligence<br />
                * Minimalism<br />
                * Micro Interactions<br />
                * Mobile-Friendly Designs<br />
                * Augmented Reality<br />
                * Endless job opportunities for web designers
              </p>
            </div>
          </article>
        </div>
        <div className="col-md-4">
          <article className="blog-post">
            <img alt="project4notfound" src={project4} />
            <a href="#" className="tag">Programming</a>
            <div className="content">
              <small>01 jan, 2023</small>
              <h5>
                Top 10 Programming Language Trends in the Year 2023
              </h5>
              <p>
                * JavaScript <br />
                * Python <br />
                * Java <br />
                * Golang <br />
                * C#          <br />
                * Kotlin <br />
                * C++<br />
              </p>
              <br></br>
            </div>
          </article>
        </div>
        <div className="col-md-4">
          <article className="blog-post">
            <img alt="project2nmotfound" src={project2} />
            <a href="#" className="tag">Technology Trends</a>
            <div className="content">
              <small>01 Jan, 2023</small>
              <h5>Top 10 New Technology Trends for 2023</h5>
              <p>
                * Artificial Intelligence <br />
                * Machine Learning <br />
                * Computing Power <br />
                * Smarter Devices<br />
                * Datafication<br />
                * Robotic Process Automation (RPA) <br />
                * Quantum Computing <br />
               
                * Internet of Things (IoT) 
              
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</div>

    </Layout>
  )
}

export default Homepage
