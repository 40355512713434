
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';

const basurl = process.env.REACT_APP_BASE_URL;

const Contact = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${basurl}api/getAllservicess`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <Layout>
        <div>
          <div className="container">
            <div><h1 style={{textAlign:"center", marginTop:"20PX"}}>Contact Us</h1></div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="container-fluid">
                  <div className="map-responsive">
                    <iframe src=" https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14675.978252518024!2d79.87172167999842!3d23.133873990684513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlot%20No%2038%20and%2039%20IT%20Park%20Bargi%20Hills%20Jabalpur%20MP!5e0!3m2!1sen!2sin!4v1670505348645!5m2!1sen!2sin" />
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="col-md-6">
                <form className="my-form">
                  <h4 className="navbar-brand">  &nbsp;Crimsons <span className="dot">Systems.</span></h4>
                  {/* Add conditional checks before accessing userData properties */}
                  {userData && userData[0] && (
                    <>
                      <p>
                        <i className="bx bx-home-circle" />
                        Address - {userData[0].address}
                      </p>
                      <p>
                        <i className="bx bx-envelope" />
                        Email : - {userData[0].email}
                      </p>
                      <p>
                        <i className="bx bx-mobile" />
                        contact :-{userData[0].contact}
                      </p>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
          <style dangerouslySetInnerHTML={{__html: "\n    .my-form {\n        color: #305896;\n    \n        }\n    .map-responsive {\n        overflow: hidden;\n        padding-bottom: 50%;\n        position: relative;\n        height: 50%;\n    }\n\n        .map-responsive iframe {\n            left: 0;\n            top: 0;\n            height: 100%;\n            width: 100%;\n            position: absolute;\n        }\n" }} />
          <br />
        </div>
      </Layout>
    </>
  );
}

export default Contact;
