



import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import axios from 'axios'; 
import { NavLink, Link } from 'react-router-dom';
import img1 from "../imgececk/service.png"
const basurl=process.env.REACT_APP_BASE_URL; 

const Services = () => {
  const [blogs, setBlogs] = useState([]);
  const [expandedBlogs, setExpandedBlogs] = useState([]);

  useEffect(() => {
  
    axios.get(`${basurl}api/getAllservices`)
      .then(response => {
        const fetchedBlogs = response.data;
        setBlogs(fetchedBlogs);
      })
      .catch(error => {
        console.error('Error fetching blog data:', error);
      });
  }, []);
console.log("blogs",blogs)
  const toggleReadMore = (id) => {
    setExpandedBlogs((prevExpandedBlogs) => {
      if (prevExpandedBlogs.includes(id)) {
        return prevExpandedBlogs.filter((blogId) => blogId !== id);
      } else {
        return [...prevExpandedBlogs, id];
      }
    });
  };

  return (
    <Layout>
      <section id="services" className="text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="intro">
             
               <h5 style={{color:"red"}}> Our Services</h5>
             
                <h1>Our Service Map</h1>
                <p className="mx-auto">
              <br />
              Our software company offers a comprehensive suite of services including logo designing, web development, software development, mobile app development, digital cards, and digital marketing. As an outsourcing development company, we specialize in delivering innovative solutions tailored to our clients' needs.
            </p>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {blogs.map((blog, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="service">
                  {/* {blog.image && (
                    // <img alt={`Dynamic Project ${blog.id}`}  src={`${basurl}${blog.image}`} />

                  
                  )} */}
                    <img alt={`Dynamic Project ${blog.id}`}  src={img1} />
            <h5>{`${blog.name}`}</h5>
                  <p>
                     {/* {blog.description} */}
                    </p>
                  {expandedBlogs.includes(blog.id) ? (
                    <>
                      <p>{blog.description}</p>
                      <button onClick={() => toggleReadMore(blog.id)}>Read Less</button>
                    </>
                  ) : (
                    <>
                      <p>{blog.description.slice(0, 100)}...</p>
                      <button onClick={() => toggleReadMore(blog.id)}>Read More</button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Services;
