import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import banner1 from "../../imgececk/newbanner1.jpeg";
import banner2 from "../../imgececk/newbanner2.jpeg";
import banner3 from "../../imgececk/newbanner3.jpeg"; // Add the third image import

const basurl = process.env.REACT_APP_BASE_URL;

const Header = () => {
  const [slides, setSlides] = useState([]); 

  useEffect(() => {
    axios.get(`${basurl}api/getAllBanner`)
      .then(response => {
        const fetchedSlides = response.data;
        setSlides(fetchedSlides);
      })
      .catch(error => {
        console.error('Error fetching slider data:', error);
      });
  }, []);
 
  const images = [banner1, banner2, banner3]; // Array containing image paths
  
  return (
    <Carousel>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <div
            className={`slide slide${index + 1}`}
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${images[index % 3]})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1 text-white">
                  <h6 className="text-white text-uppercase">
                    {slide.name}
                  </h6>
                  <h1 className="display-3 my-4">{slide.description}</h1>
                  <p>{slide.staticContent}</p>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Header;
