import React from 'react'
import Layout from '../components/Layout/Layout'

const About = () => {
  return (
   <>
<Layout>
  <title>My Page</title>
  <style dangerouslySetInnerHTML={{__html: "\n         h1 {\n            color: #ff4d29;\n            text-align: left center;\n        }\n\n      \n      \n        \n\n    " }} />
  <div className="container">
    <h1>About Us</h1>
    <h5>About company</h5>
    <p>Driving innovation in IT, our innovative company specializes in delivering
customized software solutions to businesses across industries. With a team of
highly skilled professionals, we&#39;ve established a reputation for excellence and
innovation since our inception in 2019. Founded by IT professionals who
recognized the need for high-quality services, we started with a small team
dedicated to providing exceptional solutions to clients in India and globally.</p>
<h5>Company mission</h5>
<p>Our mission is to empower businesses with the latest technologies to enhance growth,
productivity, and operational efficiency. We strongly believe in technology&#39;s potential to drive
innovation and achieve business goals. Committed to our clients&#39; success in the fast-evolving
digital landscape, we strive to deliver tailored solutions that address their unique needs. Our
strength lies in crafting customized software solutions designed to meet the specific
requirements of each client, ensuring optimal outcomes and satisfaction.</p>
<h5>Team</h5>
<p>
Our team of developers and designers work closely with clients to understand
their business objectives identifies main points, and develop solutions that
address their specific needs. In addition to custom software development,
Crimson System also offers a range of other IT services, including web
development, mobile app development, and IT consulting. We have a deep
understanding of the latest trends and technologies in the industry, and we are
committed to staying ahead of the curve to ensure that our clients receive the
best possible solutions.
</p>
<h5>Why to choose us?</h5>
<p>
We provide innovative solutions that consistently exceed expectations. Our team
fosters a welcoming and collaborative environment, ensuring a pleasant and
productive work experience. Committed to delivering on time, our experts
leverage their skills and expertise to guarantee successful outcomes for every
project. Trust us to bring your vision to life with creativity, reliability, and a
personal touch. We take pride in our track record of success, serving clients
ranging from small startups to large enterprises across diverse industries such as
healthcare, finance, and manufacturing.
</p> 
<h5>Conclusion</h5>
<p>
Crimson System is a dynamic and innovative IT company that is driving innovation
and transformation in the field of information technology. Our commitment to
delivering customized solutions, combined with our deep understanding of the
latest trends and technologies in the industry, sets us apart from our competitors.
We are proud to call Jabalpur our home, and we look forward to continuing to
serve our clients and drive innovation in the years to come.
</p>
</div>

  </Layout>

   </>
  )
}

export default About
